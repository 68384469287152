<template>
  <v-card tile>
    <v-app-bar color="primary" dark fixed flat>
      <v-btn dark icon @click.native="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-spacer/>
      <v-toolbar-title :style="{ 'margin-left': '-24px' }">
        {{ $vuetify.lang.t('$vuetify.general.inspection.siteDialog.sitesPage') }}
      </v-toolbar-title>
      <v-spacer/>
    </v-app-bar>
    <v-card class="mb-6" tile>
      <v-img :alt="`${site.name} logo`" :aspect-ratio="16/9" :src="site.logo" :style="{'margin-top': '56px'}" contain/>
    </v-card>
    <v-card-text>
      <kurcc-inspection-list-item-dialog-content-section
        :header="$vuetify.lang.t('$vuetify.general.inspection.siteDialog.sectionHeaders.generalInformation')"
        :items="generalInfoSectionData" class="mb-5"/>
      <kurcc-inspection-list-item-dialog-content-section
        :header="$vuetify.lang.t('$vuetify.general.inspection.siteDialog.sectionHeaders.branchContactInfos')"
        :items="branchContactInfoSectionData" class="mb-5"/>
      <kurcc-inspection-list-item-dialog-content-section
        :header="$vuetify.lang.t('$vuetify.general.inspection.siteDialog.sectionHeaders.employeeInfo')"
        :items="employeeInfoSectionData" class="mb-5"/>
      <kurcc-inspection-list-item-dialog-content-section
        :class="[isUpcomingPage || isTodayPage || isInspectionsPage ? 'mb-11' : 'md-5']"
        :header="$vuetify.lang.t('$vuetify.general.inspection.siteDialog.sectionHeaders.inspectionStatus')"
        :items="inspectionStatusSectionData"/>
      <v-btn v-if="isUpcomingPage" :loading="loading" bottom class="center-x" color="primary" fixed
             @click="addForToday">
        {{ $vuetify.lang.t('$vuetify.general.inspection.siteDialog.chooseForToday') }}
      </v-btn>
      <kurcc-inspection v-if="isTodayPage" :inspection="inspection" @change="dataChanged"/>
      <v-row v-if="isInspectionsPage" :style="{position: 'fixed', bottom: '16px', width: '100%'}" class="center-x"
             justify="center" no-gutters>
        <v-btn :disabled="isAssignedToMe" :loading="loading" bottom class="me-5" color="primary"
               @click="assignInspectionToMe">
          {{ $vuetify.lang.t('$vuetify.general.inspection.siteDialog.assignToMe') }}
        </v-btn>
        <v-btn :disabled="loading" bottom color="primary" @click="dialog = true">
          <v-icon left>mdi-account-plus</v-icon>
          {{ $vuetify.lang.t('$vuetify.general.inspection.siteDialog.assignTo') }}
        </v-btn>
      </v-row>
    </v-card-text>
    <kurcc-inspection-select-inspector-dialog
      :inspection-ids="[inspection.id]" :show-dialog.sync="dialog"
      @change="dataChanged"/>
  </v-card>
</template>

<script>
import Inspection from '@/modules/inspections/models/inspection'

export default {
  name: 'KurccInspectionListItemDialogContent',
  props: {
    inspection: {
      type: Inspection,
      required: false
    }
  },
  components: {
    KurccInspectionListItemDialogContentSection: () => import('@/modules/app/components/KurccInspectionListItemDialogContentSection'),
    KurccInspectionSelectInspectorDialog: () => import('@/modules/inspections/components/KurccInspectionSelectInspectorDialog'),
    KurccInspection: () => import('@/modules/inspection/components/KurccInspection')
  },
  data () {
    return {
      loading: false,
      dialog: false
    }
  },
  computed: {
    isAssignedToMe () {
      return this.inspection?.inspector?.id === this.$auth.user().id
    },
    isUpcomingPage () {
      return this.$route.name === 'upcoming'
    },
    isTodayPage () {
      return this.$route.name === 'today'
    },
    isInspectionsPage () {
      return this.$route.name === 'inspections'
    },
    site () {
      return this.inspection.site
    },
    branch () {
      return this.inspection.branch
    },
    generalInfoSectionData () {
      return [
        {
          icon: 'mdi-store',
          title: this.$vuetify.lang.t('$vuetify.general.inspection.siteDialog.sectionFields.siteName'),
          subtitle: this.site.name
        },
        {
          icon: 'mdi-calendar-check',
          title: this.$vuetify.lang.t('$vuetify.general.inspection.siteDialog.sectionFields.lastInspection'),
          subtitle: this.branch.last_done_inspection ? this.branch.last_done_inspection.inspection_ended_at : 'n/a'
        },
        {
          icon: 'mdi-calendar-arrow-right',
          title: this.$vuetify.lang.t('$vuetify.general.inspection.siteDialog.sectionFields.nextInspection'),
          subtitle: this.branch.next_inspection
            ? this.branch.next_inspection.inspection_due_date
            : this.$vuetify.lang.t('$vuetify.general.inspection.siteDialog.sectionFields.nopInspectionIsDue')
        },
        {
          icon: 'mdi-bookmark-multiple-outline',
          title: this.$vuetify.lang.t('$vuetify.general.inspection.siteDialog.sectionFields.category'),
          subtitle: this.site.category.name
        },
        {
          icon: 'mdi-trending-up',
          title: this.$vuetify.lang.t('$vuetify.general.inspection.siteDialog.sectionFields.grade'),
          subtitle: this.branch.last_done_inspection ? this.branch.last_done_inspection.grade : 'n/a'
        }
      ]
    },
    branchContactInfoSectionData () {
      return [
        {
          icon: 'mdi-map-marker-outline',
          title: this.$vuetify.lang.t('$vuetify.general.inspection.siteDialog.sectionFields.address'),
          subtitle: this.branch.full_address
        },
        {
          icon: 'mdi-email-outline',
          title: this.$vuetify.lang.t('$vuetify.general.inspection.siteDialog.sectionFields.email'),
          subtitle: this.site.email
        },
        {
          icon: 'mdi-store-outline',
          title: this.$vuetify.lang.t('$vuetify.general.inspection.siteDialog.sectionFields.branchName'),
          subtitle: `${this.branch.brand_name} #${this.branch.number}`
        }
      ]
    },
    employeeInfoSectionData () {
      return [
        {
          icon: 'mdi-account-supervisor-outline',
          title: this.$vuetify.lang.t('$vuetify.general.inspection.siteDialog.sectionFields.managersName'),
          subtitle: this.branch.manager_name
        },
        {
          icon: 'mdi-cellphone',
          title: this.$vuetify.lang.t('$vuetify.general.inspection.siteDialog.sectionFields.managersPhone'),
          subtitle: this.branch.manager_phone_number
        }
      ]
    },
    inspectionStatusSectionData () {
      return [
        {
          icon: 'mdi-calendar-arrow-right',
          title: this.$vuetify.lang.t('$vuetify.general.inspection.siteDialog.sectionFields.inspectionDueDateStatus'),
          subtitle: `The inspection will due ${this.inspection.inspection_due_date}`
        },
        {
          icon: 'mdi-account-check',
          title: this.$vuetify.lang.t('$vuetify.general.inspection.siteDialog.sectionFields.inspector'),
          subtitle: this.inspection.inspector
            ? `${this.inspection.inspector.first_name} ${this.inspection.inspector.last_name}`
            : this.$vuetify.lang.t('$vuetify.general.inspection.siteDialog.sectionFields.notAssignedYet')
        },
        {
          icon: 'mdi-clock-alert-outline',
          title: this.$vuetify.lang.t('$vuetify.general.inspection.siteDialog.sectionFields.deadlineDays'),
          subtitle: this.inspection.deadline_days
        }
      ]
    }
  },
  methods: {
    addForToday () {
      this.loading = true
      this.$store.dispatch('addForToday', {
        inspector_id: this.$auth.user().id,
        inspection_ids: [this.inspection.id]
      }).then(() => {
        this.dataChanged()
      }).finally(() => {
        this.loading = false
      })
    },
    assignInspectionToMe () {
      this.loading = true
      this.$store.dispatch('assignInspectorToInspections', {
        inspector_id: this.$auth.user().id,
        inspection_ids: [this.inspection.id]
      }).then(() => {
        this.dataChanged()
      }).finally(() => {
        this.loading = false
      })
    },
    close () {
      this.$emit('close-dialog')
    },
    dataChanged () {
      this.$emit('change')
      this.close()
    }
  }
}
</script>
